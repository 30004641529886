import React, { useState } from "react";
import { Card, Grid, Typography, Box } from "@mui/material";
import {
  PieChart as PieChartRecharts,
  Pie,
  Legend,
  Tooltip,
  Cell,
} from "recharts";
import { getColorByString } from "./tools";
import EmptyChart from "./EmptyChart";

const OPACITY_VALUE = 0.3;
/* 
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  if (percent < 0.1) return null;
  return (
    <text
      fontSize={14}
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
 */
const CustomLegend = ({
  payload,
  handleMouseEnter,
  handleMouseLeave,
  opacity,
}) => {
  const getOpacity = (index) => {
    const labelHover = opacity.filter((value) => value !== 1).length === 1;
    if (labelHover && opacity[index] === 1) return 1; // mouse hovers over the labels and the label of interest: show the label of interest
    if (labelHover && opacity[index] !== 1) return OPACITY_VALUE; // mouse hovers over the labels but not over the label of interest: hide the label
    return 1; // mouse don't hover over the labels so show the labels (default)
  };
  return (
    <Grid
      container
      spacing={1}
      paddingTop={2}
      justifyContent="center"
      flexWrap={"wrap-reverse"}
      flexDirection="row-reverse"
    >
      {payload.map((point, index) => (
        <Grid
          key={index}
          item
          display="flex"
          alignItems={"center"}
          sx={{
            gap: 1,
            opacity: getOpacity(index),
          }}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={() => handleMouseLeave(index)}
        >
          <Box
            width={"14px"}
            height={"14px"}
            borderRadius="50%"
            backgroundColor={point.color}
          />
          <Typography variant="body1" color="text.secondary">
            {point.value}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <Card sx={{ padding: 2 }}>
        <Typography variant="body1">{`${payload[0].name} : ${payload[0].value}%`}</Typography>
      </Card>
    );
  }

  return null;
};

export default function PieChart({ data }) {
  const [opacity, setOpacity] = useState(new Array(data.length).fill(1));

  const handleMouseEnter = (index) => {
    const newOpacity = opacity.map((_, i) => (index === i ? 1 : OPACITY_VALUE));
    setOpacity(newOpacity);
  };

  const handleMouseLeave = () => {
    setOpacity(new Array(data.length).fill(1));
  };

  if (data.length === 0) return <EmptyChart />;
  return (
    <PieChartRecharts margin={{ marginBottom: 30 }} width={320} height={360}>
      <Pie
        dataKey="value"
        isAnimationActive={false}
        data={data}
        cx="50%"
        cy="50%"
        //labelLine={false}
        outerRadius={90}
        //label={renderCustomizedLabel}
        //label={({ value }) => `${value}%`}
        labelLine={{ stroke: "#000" }}
        startAngle={-270}
        label={({
          cx,
          cy,
          midAngle,
          innerRadius,
          outerRadius,
          value,
          index,
        }) => {
          const RADIAN = Math.PI / 180;
          // eslint-disable-next-line
          const radius = 25 + innerRadius + (outerRadius - innerRadius);
          // eslint-disable-next-line
          const x = cx + radius * Math.cos(-midAngle * RADIAN);
          // eslint-disable-next-line
          const y = cy + radius * Math.sin(-midAngle * RADIAN);

          return (
            <text
              x={x}
              y={y}
              fill="#000"
              opacity={opacity[index]}
              textAnchor={x > cx ? "start" : "end"}
              dominantBaseline="central"
            >
              {value}%
            </text>
          );
        }}
      >
        {data.map(({ name, value }, index) => {
          return (
            <Cell
              key={index}
              fill={getColorByString(name)}
              opacity={opacity[index]}
            />
          );
        })}
      </Pie>
      <Tooltip content={<CustomTooltip />} />
      <Legend
        verticalAlign="bottom"
        content={
          <CustomLegend
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            opacity={opacity}
          />
        }
      />
    </PieChartRecharts>
  );
}
