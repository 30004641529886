import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Container from "@mui/material/Container";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";

import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";

import useTranslation from "./useTranslation";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function JoinStudy({ dark = true }) {
  const hash = dark ? "#join" : "#join2";
  const {
    joinResearch,
    close,
    download,
    nickname,
    terms,
    recovery,
    search,
    later,
  } = useTranslation();
  const [open, setOpen] = React.useState(window.location.hash === hash);

  React.useEffect(() => {
    const onHashChange = () => setOpen(window.location.hash === hash);
    window.addEventListener("hashchange", onHashChange);
    return () => window.removeEventListener("hashchange", onHashChange);
  }, [hash]);

  const handleClickOpen = () => {
    setOpen(true);
    window.location.hash = hash;
  };

  const handleClose = () => {
    setOpen(false);
    window.history.back();
  };

  const steps = [
    {
      id: "download",
      description: download,
      image: (
        <Container maxWidth={"xs"}>
          <Grid
            container
            display={"flex"}
            justifyContent={"center"}
            spacing={2}
          >
            <Grid item xs={12} md={6} display="block">
              <Box>
                <a
                  href="https://play.google.com/store/apps/details?id=io.m_Path.kuleuven&amp;gl=BE"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="./playstore.png"
                    alt="Play Store Bage"
                    height={60}
                  />
                </a>
              </Box>
              <Box padding={2} />
              <Box>
                <a
                  href="https://apps.apple.com/be/app/m-path/id1469274164?l=nl"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="./appstore.png" alt="App Store Bage" height={60} />
                </a>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <img src="./qr-code.png" alt="QR Code" height={160} />
            </Grid>
          </Grid>
        </Container>
      ),
      index: 1,
    },
    {
      id: "nickname",
      description: nickname,
      image: <img alt={"nickname"} src={"./set-up-app-1.png"} height={180} />,
      index: 2,
    },
    {
      id: "terms",
      description: terms,
      image: <img alt={"terms"} src={"./set-up-app-2.png"} height={180} />,
      index: 3,
    },
    {
      id: "recovery",
      description: recovery,
      image: "",
      index: 4,
    },
    {
      id: "search",
      description: search,
      image: <img alt={"search"} src={"./set-up-app-3.png"} height={180} />,
      index: 5,
    },
  ];

  return (
    <div>
      <Button
        variant="contained"
        endIcon={<ArrowForwardIosRoundedIcon />}
        sx={
          dark
            ? [
                {
                  color: "primary.main",
                  background: "#fff",
                },
                (theme) => ({
                  "&:hover": {
                    background: theme.palette.primary.dark,
                    color: "#fff",
                  },
                }),
              ]
            : []
        }
        onClick={handleClickOpen}
      >
        {joinResearch}
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{
            position: "relative",
          }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {joinResearch}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              {close}
            </Button>
          </Toolbar>
        </AppBar>
        <Container>
          <Box padding={2} />
          <List>
            {steps.map((step, index) => (
              <ListItem
                key={step.id}
                sx={{ display: "block", textAlign: "center" }}
              >
                <Box
                  sx={{
                    backgroundColor: "primary.main",
                    color: "#fff",
                    width: 60,
                    height: 60,
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "auto",
                  }}
                >
                  <Typography variant="h4">{index + 1}</Typography>
                </Box>
                <Box padding={1} />
                <Typography variant="h5">{step.description}</Typography>
                <Box padding={1} />
                <Box>{step.image}</Box>
                <Box padding={3} />
              </ListItem>
            ))}
          </List>
          <List>
            <ListItem sx={{ display: "block", textAlign: "center" }}>
              <Typography variant="h5">{later}</Typography>
              <Box padding={1} />
              <Box>
                <img alt={later} src={"./set-up-app-4.png"} height={180} />
              </Box>
            </ListItem>
          </List>
          <Box padding={3} />
        </Container>
      </Dialog>
    </div>
  );
}
