import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Box,
  Button,
  Typography,
  TextField,
  DialogContent,
  DialogActions,
  DialogTitle,
  Dialog
} from "@mui/material";

import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";

import useTranslation from "./useTranslation";

function ConfirmationDialog(props) {
  const { open, handleClose } = props;
  const { join, confirmJoin, close } = useTranslation();

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{join}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">{confirmJoin}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="inherit">
          {close}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const RedditTextField = styled((props) => (
  <TextField
    InputProps={{
      style: {
        //color: "#fff",
        width: 300,
        height: 56,
        "& p": {
          //color: "#fff",
        },
      },
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiFormHelperText-root": {
    //color: "#fff",
  },
  "& label.Mui-focused": {
    //color: "#fff",
  },
  "& .MuiInput-underline:after": {
    //borderBottomColor: "#fff",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      //borderColor: "#fff",
      //color: "#fff",
      borderColor: "rgba(25, 118, 210, 0.5)",
    },
    "&:hover fieldset": {
      //borderColor: "#fff",
      //color: "#fff",
      borderColor: "rgba(25, 118, 210, 0.5)",
    },
    "&.Mui-focused fieldset": {
      //borderColor: "#fff",
      //color: "#fff",
      borderColor: "rgba(25, 118, 210, 0.5)",
    },
  },
}));

function isValidEmail(email) {
  return /\S+@\S+\.\S+/.test(email);
}

export default function JoinStudyMail() {
  const { join, joinMessage, emailPlaceholder, wrongMail, wrong } =
    useTranslation();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false); // posting
  const [error, setError] = useState(null); // display post error
  const [open, setOpen] = useState(false); // open success message

  const handleClose = () => setOpen(false); // show the confirmation dialog

  // Using Fetch API
  const postMail = () => {
    fetch(process.env.REACT_APP_JOIN_RESEARCH, {
      method: "POST",
      body: JSON.stringify({
        email: email,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle data
        if (data.status === 1) {
          setOpen(true);
          setLoading(false);
        } else {
          setError(wrong);
          setLoading(false);
        }
      })
      .catch((err) => {
        setError(wrong);
        console.log(err.message);
      });
  };

  // Click on join button
  const handleClick = () => {
    if (!isValidEmail(email)) {
      setError(wrongMail);
    } else {
      setError(null);
      setLoading(true);
      postMail();
    }
  };

  const handleChange = (event) => setEmail(event.target.value); // change the email textfield
  const handleKeyPress = (e) => {
    // simulate a click on join button when enter is pressed in textfield
    if (e.key === "Enter") {
      handleClick();
    }
  };

  return (
    <Box>
      <ConfirmationDialog open={open} handleClose={handleClose} />
      <Typography variant="h6">{joinMessage}</Typography>
      <Box padding={1} />
      <Grid container spacing={2}>
        <Grid item>
          <RedditTextField
            type="email"
            placeholder={emailPlaceholder}
            value={email}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            helperText={
              error ? (
                <Box
                  display={"flex"}
                  alignItems="center"
                  sx={{ gap: 1, paddingTop: 1, color: "error.main" }}
                >
                  <ErrorOutlineRoundedIcon fontSize="small" />
                  <Typography variant="body1" fontWeight={600}>
                    {error}
                  </Typography>
                </Box>
              ) : null
            }
          />
        </Grid>
        <Grid item>
          <Button
            disabled={loading}
            variant="outlined"
            endIcon={<ArrowForwardIosRoundedIcon />}
            sx={[
              {
                //color: "#fff",
                //borderColor: "#fff",
                height: 56,
              },
              (theme) => ({
                "&:hover": {
                  //background: theme.palette.primary.dark,
                  //color: "#fff",
                },
              }),
            ]}
            onClick={handleClick}
          >
            {join}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
