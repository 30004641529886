export const appReducer = (app, action) => {
  switch (action.type) {
    case "CHANGE_LANG":
      const lang = action.payload.includes("nl") ? "nl" : "en";
      const langIndex = lang === "nl" ? 0 : 1;
      if (lang)
        return {
          ...app,
          lang,
          langIndex,
        };
      return app;
    default:
      return app;
  }
};
