import React, { useContext } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@emotion/react";
import { Box, Tooltip, Typography, Container, Card, Grid } from "@mui/material";

import LanguageIcon from "@mui/icons-material/Language";
import AcUnitRoundedIcon from "@mui/icons-material/AcUnitRounded";

import AppContext from "./appContext";
import SelectButtonMenu from "./SelectButtonMenu";
import JoinStudy from "./JoinStudy";
import useTranslation from "./useTranslation";

const languages = [
  { id: "nl", title: "Nederlands" },
  { id: "en", title: "English" },
];

export default function Toolbar() {
  const theme = useTheme();
  const {
    app: { lang },
    reducer,
  } = useContext(AppContext);
  const { gotoMPath, subtitle, description, changeLang } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const showMail = window.location.search.includes("mail");
  return (
    <Box>
      <Container maxWidth="xl">
        {/** Brand and language selector */}
        <Box display={"flex"} justifyContent="space-between">
          <Tooltip title={gotoMPath}>
            <img
              alt="Logo m-Path KULeuven"
              src="./logo-horizontal.png"
              height={50}
              sx={{ opacity: 1 }}
              onClick={() => (window.location = "https://m-path.io")}
              style={{ cursor: "pointer" }}
            />
          </Tooltip>
          <SelectButtonMenu
            buttonText={lang}
            buttonTooltip={changeLang}
            options={languages.map((l) => l.id)}
            titles={languages.map((l) => l.title)}
            handleOptionClick={(index) =>
              reducer({ type: "CHANGE_LANG", payload: languages[index].id })
            }
            startButtonIcon={<LanguageIcon />}
          />
        </Box>
        <Box padding={1} />
        {/** Landing section */}
        <Card sx={{ backgroundColor: "rgb(1, 19, 54)", color: "#fff" }}>
          <Container>
            <Box padding={2} />
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                {/** Underline winter */}
                <svg
                  style={{ marginBottom: "-90px" }}
                  width="220"
                  height="24"
                  viewBox="0 0 220 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M134.66 13.107c-10.334-.37-20.721-.5-31.12-.291l-2.6.06c-4.116.04-8.193.602-12.3.749-14.502.43-29.029 1.196-43.514 2.465-6.414.63-12.808 1.629-19.04 2.866-7.93 1.579-16.113 3.71-23.367 5.003-2.211.374-3.397-1.832-2.31-4.906.5-1.467 1.838-3.456 3.418-4.813a16.047 16.047 0 0 1 6.107-3.365c16.88-4.266 33.763-6.67 51.009-7.389C71.25 3.187 81.81 1.6 92.309.966c11.53-.65 23.097-.938 34.66-.96 7.117-.054 14.25.254 21.36.318l16.194.803 4.62.39c3.85.32 7.693.618 11.53.813 8.346.883 16.673.802 25.144 2.159 1.864.276 3.714.338 5.566.873l.717.225c6.162 1.977 7.92 3.64 7.9 7.197l-.003.203c-.017.875.05 1.772-.112 2.593-.581 2.762-4.066 4.12-8.637 3.63-13.696-1.06-27.935-3.332-42.97-4.168-11.055-.83-22.314-1.459-33.596-1.603l-.022-.332Z"
                    fill="#D1D5DB"
                    fillRule="evenodd"
                  ></path>
                </svg>
                <Typography
                  /*          sx={{
            background: "-webkit-linear-gradient(#338CF5, #4FD1C5)",
            "-webkit-background-clip": "text",
            "-webkit-text-fill-color": "transparent",
          }} */
                  variant="h2"
                  //textAlign="center"
                >
                  <span
                    //style={{ color: theme.palette.primary.light }}
                    style={{
                      background: "-webkit-linear-gradient(#338CF5, #4FD1C5)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    Winter
                  </span>
                  <span> is coming </span>
                  <span>
                    <AcUnitRoundedIcon fontSize="large" />
                  </span>
                </Typography>
                <Box padding={3} />
                <Typography variant="h4">{subtitle}</Typography>
                <Box padding={2} />
                <Typography variant="body1">{description}</Typography>
                <Box padding={2} />
                {showMail ? null : <JoinStudy />}
              </Grid>
              <Grid item xs={12} md={6} textAlign="end">
                {/** Hide image on mobile devices */}
                {!isMobile ? (
                  <img
                    alt="smartphone"
                    src={`./phone2-${lang}.png`}
                    height={480}
                  />
                ) : null}
              </Grid>
            </Grid>
            <Box padding={3} />
          </Container>
        </Card>
      </Container>
    </Box>
  );
}
