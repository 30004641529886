import { useState, useContext } from "react";

import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import AppContext from "./appContext";
import SelectButtonMenu from "./SelectButtonMenu";
import useTranslation from "./useTranslation";

export default function ChartCard({ item, Chart }) {
  const {
    app: { langIndex },
  } = useContext(AppContext);
  const translation = useTranslation();
  const buttonTooltip = translation.changeSplit;
  const title = item.TITLE[langIndex];
  const description = item.DESCRIPTION[langIndex];
  const splitLabels = item.LABEL[langIndex];
  const [splitIndex, setSplitIndex] = useState(0);
  return (
    <Card elevation={0}>
      <CardContent>
        <Box display={"flex"} justifyContent="space-between">
          <Typography variant="h6" color="text.primary" gutterBottom>
            {title}
          </Typography>
          {item.TYPE !== "barchart" ? (
            <SelectButtonMenu
              options={splitLabels}
              buttonText={splitLabels[splitIndex]}
              handleOptionClick={(index) => setSplitIndex(index)}
              buttonTooltip={buttonTooltip}
            />
          ) : null}
        </Box>
        <Typography variant="body1" color="text.secondary" component="div">
          {description}
        </Typography>
        <Box padding={1} />
        <Chart item={item} splitIndex={splitIndex} />
      </CardContent>
    </Card>
  );
}
