import { useContext } from "react";
import AppContext from "./appContext";
import { translationObject } from "./translation";

export default function useTranslation() {
  const {
    app: { lang },
  } = useContext(AppContext);
  return translationObject?.[lang] ?? translationObject.en;
}
