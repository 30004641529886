import React from "react";
import { Grid, Typography, Box, Container } from "@mui/material";

import PeopleOutlineRoundedIcon from "@mui/icons-material/PeopleOutlineRounded";

import useTranslation from "./useTranslation";
import { getMoment } from "./tools";
import ChartCard from "./ChartCard";
import ContinuousSplit from "./ContinuousSplit";
import Mean from "./Mean";
import MultipleChoice from "./MultipleChoice";
import BarChart from "./BarChart";

const getChart = (type) => {
  switch (type) {
    case "continuousSplit":
      return ContinuousSplit;
    case "multipleChoice":
      return MultipleChoice;
    case "mean":
      return Mean;
    case "barchart":
      return BarChart;
    default:
      return Box;
  }
};

export default function Dashboard() {
  const { lastUpdated } = useTranslation();
  const [data, setData] = React.useState({ LASTDAILYDIARY: 0, TOPLOT: [] });
  React.useEffect(() => {
    fetch(`data.json?v=${Math.round(Math.random(10000000) * 10000000)}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseData) => {
        setData(responseData);
      })
      .catch((error) => console.warn(error));
  }, []);

  return (
    <Container>
      <a href="https://m-path.io">
        <img
          src="./decoration.png"
          style={{ position: "absolute", zIndex: -1, left: 0, height: 120 }}
          alt="decoration"
        />
      </a>
      <Typography variant="h6" fontWeight={700}>
        Dashboard
      </Typography>
      <Typography variant="body1" color="text.secondary">
        {lastUpdated}{" "}
        {getMoment(data.LASTDAILYDIARY * 1000).format("DD/MM HH:mm")}
      </Typography>
      <Typography
        variant="body1"
        color="text.secondary"
        sx={{ display: "flex", alignItem: "center", gap: 1 }}
      >
        <PeopleOutlineRoundedIcon /> {data.NPARTICIPANTS}
      </Typography>
      <Box padding={2} />
      <Grid container spacing={4}>
        {data.TOPLOT.map((item, index) => (
          <Grid key={index} item xs={12} md={item.TYPE === "mean" ? 6 : 12}>
            <ChartCard item={item} Chart={getChart(item.TYPE)} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
