import "@fontsource/raleway/300.css";
import "@fontsource/raleway/400.css";
import "@fontsource/raleway/500.css";
import "@fontsource/raleway/600.css";
import "@fontsource/raleway/700.css";

import { useReducer } from "react";
import { Box, ThemeProvider, createTheme } from "@mui/material";
import { appReducer } from "./appReducer";
import Toolbar from "./Toolbar";
import { AppProvider } from "./appContext";
import Dashboard from "./Dashboard";
import Description from "./Description";
import Footer from "./Footer";

const theme = createTheme({
  palette: {
    text: {
      primary: "#0e1446",
    },
  },
  shape: {
    borderRadius: 16,
  },
  typography: {
    fontFamily: "Raleway, Arial",
    h1: {
      fontWeight: 700,
    },
    h2: {
      fontWeight: 700,
    },
    h3: {
      fontWeight: 700,
    },
    h4: {
      fontWeight: 500,
    },
    h6: {
      fontWeight: 700,
    },
    body1: {
      fontWeight: 500,
    },
    button: {
      fontWeight: 600,
    },
    subtitle2: {
      fontWeight: 600,
    },
  },
});

/* const initApp = () => {
  // detect browser lang TO TEST
  const lang = window.navigator.language || window.navigator.userLanguage;
  if (lang.includes("nl")) return { lang: "nl", langIndex: 0 };
  return { lang: "en", langIndex: 1 };
}; */

function App() {
  const [app, reducer] = useReducer(appReducer, { lang: "nl", langIndex: 0 });
  const showMail = window.location.search.includes("mail");
  return (
    <ThemeProvider theme={theme}>
      <AppProvider value={{ app, reducer }}>
        <Box padding={1} />
        <Toolbar />
        {showMail ? (
          <Box>
            <Box padding={2} />
            <Description />
          </Box>
        ) : null}
        <Box padding={2} />
        <Dashboard />
        <Box padding={2} />
        {showMail ? null : (
          <Box>
            <Box padding={2} />
            <Description />
          </Box>
        )}
        <Box padding={2} />
        <Footer />
        <Box padding={1} />
      </AppProvider>
    </ThemeProvider>
  );
}

export default App;
