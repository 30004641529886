import { Card, Container, Box, Grid, Typography } from "@mui/material";
import JoinStudy from "./JoinStudy";
import JoinStudyMail from "./JoinStudyMail";
import useTranslation from "./useTranslation";

export default function Description() {
  const { why, description1, description2 } = useTranslation();
  const showMail = window.location.search.includes("mail");
  return (
    <Container maxWidth="xl">
      <Card>
        <Container>
          <Box padding={2} />
          <Typography variant="h6">{why}</Typography>
          <Box padding={1} />
          <Grid container spacing={3}>
            <Grid item md={6}>
              <Typography variant="body1" textAlign={"justify"}>
                {description1}
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography variant="body1" textAlign={"justify"}>
                {description2}
              </Typography>
            </Grid>
          </Grid>
          <Box padding={2} />
          {showMail ? <JoinStudyMail /> : <JoinStudy dark={false} />}
          <Box padding={2} />
        </Container>
      </Card>
    </Container>
  );
}
