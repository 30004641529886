import * as React from "react";
import { useContext } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AppContext from "./appContext";
import PieChart from "./PieChart";

export default function MultipleChoice({ item, splitIndex }) {
  const {
    app: { langIndex },
  } = useContext(AppContext);
  const names = item?.NAMES?.[langIndex]?.[splitIndex];
  const values = item?.SPLIT?.[splitIndex];
  const categories = item?.CATEGORIES?.[langIndex]?.[splitIndex];

  const getData = (index) => {
    let d = [];
    for (let j = 0; j < categories?.[index]?.length; j++) {
      let obj = {};
      const name = categories?.[index]?.[j];
      const value = values?.[index]?.[j];
      if (name.length !== 0 && value !== null) {
        obj["name"] = name;
        obj["value"] = Math.round(value * 100);
        d.push(obj);
      }
    }
    return d.sort(
      (a, b) => a.value - b.value - ["anders", "other"].includes(a.name) * 1000
    ); // sort dec
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={6}>
        <Box
          display="flex"
          justifyContent={"center"}
          flexDirection="column"
          alignItems={"center"}
        >
          <Typography variant="subtitle2" sx={{ pb: 2 }}>
            {names[0]}
          </Typography>
          <PieChart data={getData(0)} />
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box
          display="flex"
          justifyContent={"center"}
          flexDirection="column"
          alignItems={"center"}
        >
          <Typography variant="subtitle2" sx={{ pb: 2 }}>
            {names[1]}
          </Typography>
          <PieChart data={getData(1)} />
        </Box>
      </Grid>
    </Grid>
  );
}
