import { Typography, Box } from "@mui/material";

import MailOutlineRoundedIcon from "@mui/icons-material/MailOutlineRounded";

export default function Footer() {
  return (
    <Box justifyContent="center" display={"flex"} sx={{ gap: 1 }}>
      <MailOutlineRoundedIcon />
      <Typography variant="body1">
        <a href="mailto:winter-is-coming@kuleuven.be">
          winter-is-coming@kuleuven.be
        </a>
      </Typography>
    </Box>
  );
}
