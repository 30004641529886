import React, { useContext, useState } from "react";
import {
  Card,
  Box,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";
import {
  BarChart as BarChartRechart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer,
  ErrorBar,
} from "recharts";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@emotion/react";
import AppContext from "./appContext";
import { getColor } from "./tools";
import useTranslation from "./useTranslation";

const CustomizedAxisTick = (props) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-35)"
      >
        {payload.value}
      </text>
    </g>
  );
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Card sx={{ padding: 2 }}>
        {payload.map((point, index) => (
          <Box key={index} display="flex" alignItems={"center"} sx={{ gap: 1 }}>
            <Box
              width={14}
              height={14}
              borderRadius="50%"
              backgroundColor={point.color}
            />
            <Typography variant="body1">{`${label} : ${point.value}`}</Typography>
          </Box>
        ))}
      </Card>
    );
  }
  return null;
};

export default function BarChart({ item }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    app: { langIndex },
  } = useContext(AppContext);
  const { showErrorBars } = useTranslation();
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const min = Math.round(Math.min(...item.SPLIT)) - 3;
  const max = Math.round(Math.max(...item.SPLIT)) + 3;

  const getData = () => {
    const conf = item.CONF;
    const yValues = item.SPLIT.map(
      (num) => Math.round((num + Number.EPSILON) * 100) / 100
    );
    const names = item.LABEL[langIndex];
    let data = [];
    for (let i = 0; i < yValues.length; i++) {
      let obj = {};
      obj["name"] = names[i];
      obj["value"] = yValues[i];
      obj["conf"] = conf[i];
      data.push(obj);
    }
    return data;
  };
  return (
    <Box>
      <ResponsiveContainer width="100%" height={250}>
        <BarChartRechart
          width={500}
          height={300}
          data={getData()}
          margin={{
            top: 5,
            right: 30,
            left: 10,
            bottom: 5,
          }}
        >
          <XAxis
            height={isMobile ? 80 : 30}
            tick={isMobile ? <CustomizedAxisTick /> : {}}
            interval={0}
            dataKey="name"
          />
          <YAxis domain={[min, max]} ticks={[min, 0, max]} />
          <Tooltip content={<CustomTooltip />} />
          <ReferenceLine y={0} stroke="#000" />
          <Bar dataKey="value" fill={getColor(0)} barSize={50}>
            {checked ? (
              <ErrorBar width={0} strokeWidth={1} dataKey={"conf"} />
            ) : null}
          </Bar>
        </BarChartRechart>
      </ResponsiveContainer>
      <FormGroup sx={{ alignItems: "flex-end" }}>
        <FormControlLabel
          control={<Switch checked={checked} onChange={handleChange} />}
          label={showErrorBars}
        />
      </FormGroup>
    </Box>
  );
}
