import { useState } from "react";
import { Card, Box, Typography, Grid } from "@mui/material";
import {
  AreaChart as AreaChartRecharts,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { getColor, getMoment } from "./tools";
//import { curveCardinal } from "d3-shape";

const OPACITY_VALUE = 0.3;

const CustomLegend = ({
  payload,
  handleMouseEnter,
  handleMouseLeave,
  opacity,
}) => {
  const getOpacity = (index) => {
    const labelHover = opacity.filter((value) => value !== 1).length === 1;
    if (labelHover && opacity[index] === 1) return 1; // mouse hovers over the labels and the label of interest: show the label of interest
    if (labelHover && opacity[index] !== 1) return OPACITY_VALUE; // mouse hovers over the labels but not over the label of interest: hide the label
    return 1; // mouse don't hover over the labels so show the labels (default)
  };
  return (
    <Grid container spacing={2} paddingBottom={2} justifyContent="center">
      {payload.map((point, index) => (
        <Grid
          key={index}
          item
          display="flex"
          alignItems={"center"}
          sx={{
            gap: 1,
            opacity: getOpacity(index),
          }}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={() => handleMouseLeave(index)}
        >
          <Box
            width={"14px"}
            height={"14px"}
            borderRadius="50%"
            backgroundColor={point.color}
          />
          <Typography variant="body1" color="text.secondary">
            {point.value}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Card sx={{ padding: 2 }}>
        <Typography variant="subtitle2">
          {getMoment(label * 1000).format("DD/MM HH:mm")}
        </Typography>
        {payload.map((point, index) => (
          <Box key={index} display="flex" alignItems={"center"} sx={{ gap: 1 }}>
            <Box
              width={14}
              height={14}
              borderRadius="50%"
              backgroundColor={point.color}
            />
            <Typography variant="body1">{`${point.name} : ${point.value}`}</Typography>
          </Box>
        ))}
      </Card>
    );
  }
  return null;
};

//const cardinal = curveCardinal.tension(0);

export default function AreaChart({
  data,
  dataKeyXAxis,
  dataKeysYAxis,
  absoluteYValues = false,
}) {
  const [opacity, setOpacity] = useState(
    new Array(dataKeysYAxis.length).fill(1)
  );

  const handleMouseEnter = (index) => {
    const newOpacity = opacity.map((_, i) => (index === i ? 1 : OPACITY_VALUE));
    setOpacity(newOpacity);
  };

  const handleMouseLeave = () => {
    setOpacity(new Array(dataKeysYAxis.length).fill(1));
  };

  return (
    <ResponsiveContainer width={"95%"} height={250}>
      <AreaChartRecharts
        width={730}
        height={250}
        data={data}
        margin={{ top: 10, right: 10, left: 10, bottom: 0 }}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={getColor(0)} stopOpacity={0.15} />
            <stop offset="95%" stopColor={getColor(0)} stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={getColor(1)} stopOpacity={0.15} />
            <stop offset="95%" stopColor={getColor(1)} stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis
          axisLine={false}
          tickLine={false}
          dataKey={dataKeyXAxis}
          tickFormatter={(timestamp, index) =>
            index % Math.round(data.length / 10) === 0
              ? getMoment(timestamp * 1000).format("DD/MM")
              : ""
          } // render 10 ticks on the xAxis
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          type="number"
          domain={absoluteYValues ? [0, "auto"] : [0, 100]}
          unit=""
        />
        <Legend
          //height={40}
          verticalAlign="top"
          content={
            <CustomLegend
              handleMouseEnter={handleMouseEnter}
              handleMouseLeave={handleMouseLeave}
              opacity={opacity}
            />
          }
        />
        <Tooltip
          content={<CustomTooltip />}
          labelFormatter={(timeStamp) =>
            getMoment(timeStamp * 1000).format("DD/MM HH:mm")
          }
        />
        <Area
          //dot={{ strokeWidth: 0, fill: getColor(0), stroke: getColor(0) }}
          //type={cardinal}
          type="monotone"
          dataKey={dataKeysYAxis[0]}
          stroke={getColor(0)}
          strokeWidth={opacity[0] === 1 ? 3 : 0}
          fillOpacity={opacity[0]}
          fill="url(#colorUv)"
          activeDot={{ r: 4 }}
        />
        <Area
          //dot={{ strokeWidth: 0, fill: getColor(1), stroke: getColor(1) }}
          //type={cardinal}
          type="monotone"
          dataKey={dataKeysYAxis[1]}
          stroke={getColor(1)}
          strokeWidth={opacity[1] === 1 ? 3 : 0}
          fillOpacity={opacity[1]}
          fill="url(#colorPv)"
          activeDot={{ r: 4 }}
        />
      </AreaChartRecharts>
    </ResponsiveContainer>
  );
}
