export const translationObject = {
  nl: {
    title: "Winter is Coming",
    subtitle: "Mentale gezondheid in onzekere tijden",
    description:
      "Europa staat voor een moeilijke winter: inflatie, oorlog, een haperende economie en onbetaalbare energieprijzen. We weten dat deze stressvolle situaties een langdurige negatieve psychologische impact kunnen hebben op de maatschappij. Het is daarom onze bedoeling om een vinger aan de pols te houden. Met deze studie willen we het mentale welzijn van de Vlaming het komende jaar goed opvolgen. We willen weten hoe de Vlaming dit jaar ervaart en hoe de Vlaming naar de toekomst kijkt.",
    why: "Waarom dit onderzoek?",
    description1:
      "Het belang van mentaal welzijn kan in de huidige maatschappelijke context niet overschat worden. Terwijl dat onderwerp al langer uitzonderlijke academische en medische belangstelling geniet, brengt de tegenwoordige stand van zaken unieke uitdagingen met zich mee. Globale crisissen raken iedereen op een andere manier, en het is de volledige reikwijdte van dat effect dat we onder de loep willen nemen. Met behulp van m-Path, een app ontworpen voor continue emotionele en mentale opvolging, volgen we een grote groep Vlamingen in uiteenlopende sociale en financiële omstandigheden doorheen deze turbulente periode. Dagelijkse metingen laten ons toe emoties meteen te linken aan concrete actuele of persoonlijke gebeurtenissen, en door middel van geavanceerde data-analyses krijgen we een beeld van hoe zulke (inter)nationale crisissen zich doorheen de psyche van Vlaamse bevolking manifesteert.",
    description2:
      "Dat is echter niet alles. Met deze studie peilen we ook naar het toekomstbeeld van de Vlaming. Wanneer de wereld voortdurend in onzekere omstandigheden verkeert, is kennis over wat er komen gaat onvoorstelbaar waardevol. Winter is Coming wilt dan ook onderzoeken welke invloed mentale gezondheid heeft op het toekomstbeeld van de Vlaming, en of de “kennis van de massa” voorspellingen kan genereren om een stem te vormen in een debat dat normaal gezien uitsluitend door experten wordt gevoerd.",
    lastUpdated: "Laatste update op",
    gotoMPath: "Ga naar m-Path",
    joinMessage: "Deelnemen?",
    joinResearch: "Deelnemen aan onderzoek",
    join: "Deelnemen",
    emailPlaceholder: "Vul uw email in",
    wrongMail: "Ongeldige email",
    wrong: "Er ging iets verkeerd",
    confirmJoin:
      "Bedankt om deel te nemen! We contacteren u zo spoedig mogelijk.",
    close: "Sluiten",
    download: "Dowload de m-Path app in de store of scan de QR-code",
    nickname: "Kies een nickname",
    terms: "Accepteer de voorwaarden",
    recovery: "Bewaar je herstelcode",
    search: (
      <span>
        Zoek op onderzoek: <strong>winter is coming</strong> en bevestig
      </span>
    ),
    later: "Of doe deze stap later:",
    changeLang: "Taal veranderen",
    changeSplit: "Groep veranderen",
    noGraph: "Geen grafiek gevonden",
    showErrorBars: "Toon foutbalken",
  },
  en: {
    title: "Winter is Coming",
    subtitle: "Mental health in uncertain times",
    description:
      "Europe is facing a difficult winter: inflation, war, a faltering economy and unaffordable energy prices. We know that these stressful situations can have a long-term negative psychological impact on society. It is therefore our intention to keep a finger on the pulse. With this study, we want to monitor the mental well-being of the Flemish (part of Belgium) people over the next year. We want to know how the Flemish population experiences this year and how the Flemish population looks to the future.",
    why: "Why this research?",
    description1:
      "The importance of mental wellbeing in the current societal context can not be overstated. While the subject has received exceptional academical and medical attention for a while, the current state of affairs brings unique challenges. Crises on a global scale affect everyone differently, and it’s the full span of these effects that we intend to take a closer look at. With m-Path, an application designed for ambulatory emotional and mental assessments, we will track a large group of Flemish citizens in various social and financial situations throughout the coming year, one that’s bound to be fraught with uncertainty. Daily measurements allow us to directly link emotional fluctuations to global or personal events, en by way of advanced data analyses we can get an idea of how these (inter)national crises manifest themselves in the Flemish psyche.",
    description2:
      "That’s not all however. With this study, we also investigate Flemish citizens’ vision of the future. When the world finds itself in uncertain circumstances, knowledge about what’s to come is unimaginably valuable. Winter is Coming aims to investigate the impact of mental wellbeing on the individuals’ perspective on the future, and wants to find out if the “wisdom of the crowd” can generate predictions to provide an additional voice in a debate that is usually reserved for experts only.",
    lastUpdated: "Last update on",
    gotoMPath: "Go to m-Path",
    joinMessage: "Join this Dutch research",
    joinResearch: "Join Dutch research",
    join: "Join",
    emailPlaceholder: "Enter your email",
    wrongMail: "Wrong email",
    wrong: "Something went wrong",
    confirmJoin: "Thanks for joining! We will contact you as soon as possible.",
    close: "Close",
    download: "Dowload the m-Path app in the store or scan the QR-code",
    nickname: "Choose a nickname",
    terms: "Accept the terms",
    recovery: "Save your recovery code",
    search: (
      <span>
        Search for research: <strong>winter is coming</strong> and confirm
      </span>
    ),
    later: "Or do this step later:",
    changeLang: "Change language",
    changeSplit: "Change group",
    noGraph: "No graph found",
    showErrorBars: "Show error bars",
  },
};
