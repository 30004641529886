import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Tooltip } from "@mui/material";

export default function SelectButtonMenu({
  buttonText,
  buttonTooltip = "",
  options,
  titles = options,
  handleOptionClick,
  startButtonIcon,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClick = (index) => {
    handleOptionClick(index);
    handleClose();
  };

  return (
    <div>
      <Tooltip title={buttonTooltip}>
        <Button
          id="change-split-button"
          aria-controls={open ? "split-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleButtonClick}
          startIcon={startButtonIcon}
          endIcon={open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        >
          {buttonText}
        </Button>
      </Tooltip>
      <Menu
        disableScrollLock
        id="split-menu"
        aria-labelledby="change-split-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            selected={buttonText === option}
            key={index}
            onClick={() => handleMenuItemClick(index)}
          >
            {titles[index]}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
