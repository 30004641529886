import * as React from "react";
import { useContext } from "react";

import AppContext from "./appContext";
import AreaChart from "./AreaChart";
//import LineChart from "./LineChart";

export default function ContinuousSplit({ item, splitIndex }) {
  const {
    app: { langIndex },
  } = useContext(AppContext);
  const names = item?.NAMES?.[langIndex]?.[splitIndex];
  const yValues = item?.SPLIT?.[splitIndex];
  const xValues = item?.X;
  const getData = () => {
    let d = [];
    for (let i = 0; i < xValues.length; i++) {
      let obj = {};
      obj["time"] = xValues[i];
      obj[`${names?.[0]}`] = Math.round(yValues?.[0]?.[i]);
      obj[`${names?.[1]}`] = Math.round(yValues?.[1]?.[i]);
      d.push(obj);
    }
    return d;
  };
  return (
    <AreaChart
      data={getData()}
      dataKeyXAxis={"time"}
      dataKeysYAxis={names}
      absoluteYValues={item.absoluteYValues}
    />
  );
}
