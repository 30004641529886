import * as React from "react";
import { useContext } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AppContext from "./appContext";
import Progress from "./Progress";
import { getColor } from "./tools";

export default function Mean({ item, splitIndex }) {
  const {
    app: { langIndex },
  } = useContext(AppContext);
  const names = item?.NAMES?.[langIndex]?.[splitIndex];
  const values = item?.SPLIT?.[splitIndex];
  return (
    <Grid container spacing={1}>
      <Grid item xs={6} textAlign={"center"}>
        <Typography variant="subtitle2" sx={{ pb: 2 }}>
          {names[0]}
        </Typography>
        <Progress value={values[0]} color={getColor(0)} />
      </Grid>
      <Grid item xs={6} textAlign={"center"}>
        <Typography variant="subtitle2" sx={{ pb: 2 }}>
          {names[1]}
        </Typography>
        <Progress value={values[1]} color={getColor(1)} />
      </Grid>
    </Grid>
  );
}
