import chroma from "chroma-js";
import moment from "moment";

const colors = [
  "#82c4ea",
  "#003f5c",
  "#665191",
  "#2f4b7c",
  "#a05195",
  "#ff7c43",
  "#d45087",
  "#f95d6a",
  "#ffa600",
];

export const getColor = (index) => colors[index];

export const getColorByString = (str) => {
  if (["other", "anders"].includes(str)) return "#8c8c8c";
  let hash = 0;

  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const index = Math.abs(hash) % 360;
  return chroma.scale(colors).mode("lch").colors(100)[index % 100];
};

export const getMoment = (time) => {
  return moment(time);
};
