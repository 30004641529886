import QueryStatsRoundedIcon from "@mui/icons-material/QueryStatsRounded";
import { Typography } from "@mui/material";
import useTranslation from "./useTranslation";

export default function EmptyChart() {
  const { noGraph } = useTranslation();
  return (
    <div>
      <QueryStatsRoundedIcon
        sx={{ width: 96, height: 96, color: "text.secondary", opacity: 0.2 }}
      />
      <Typography variant="body1" color="text.secondary">{noGraph}</Typography>
    </div>
  );
}
